import axios from 'axios'

const apiClient = axios.create ({
    baseURL: process.env.VUE_APP_API_SERVER,
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
        //'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
})

export default {   
    getBlog(blogID) {
        return apiClient.get('api/v1/blog/' + blogID)
    },
    getBlogImages(blogID) {
        return apiClient.get('api/v1/blog/images/' + blogID)
    },
    getBlogs() {
        return apiClient.get('api/v1/blogs')
    },
    getBlogsById(ids) {
        return apiClient.post('api/v1/blogs/id', {id: ids})
    },
    getArchives() {
        return apiClient.get('api/v1/archives')
    }
}

