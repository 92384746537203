<template>
  <div>
    <Environment v-if="environment != 'production'"/>
    <Header class="resourceheader" />
      <div class="container-fluid maincontentcontainer" v-if="posts && archives">
      <div class="container" style="text-align:left;">
      <div class="col-xs-12 col-sm-3">
        <h2>Archives</h2>
        <ul class="archive-list">
        
        <li class="archive-list__item" style="padding:2px 0px 2px 5px;color: white;" v-for="(archive,index) in archives" :key="index" @click="getArchivedMonth(archive.postIds)">{{ postArchiveDate(archive.month) }}</li>
        
        </ul>
        <img class="archive-list__image" src="../assets/images/clawimage.png" />
      </div>
      <div class="col-xs-12 col-sm-9" style="margin-top:-40px;">
      
      <div class="postcontainer">
        <div v-for="(post,index) in posts" :key="index">
          <div class="col-xs-12 solid-border-blog">
          <h1 v-html="post.title"></h1>
        </div>
        <div class="col-xs-12 post-date">
          {{ postDate(post.date) }}
        </div>
        <div class="col-xs-12">
          <div class="row">
            <div class="col-xs-12 col-sm-3">
              <div>
                <img class="small-image" src="../assets/images/blogpost5.png">
              </div>
            </div>
            <div class="col-xs-12 col-sm-9"> 
              <p v-html="shortPost(post.post)"/>
              <br/><br/><p @click="showDetail(post)" class="readmorebutton">Read More</p>
            </div>
          </div>
        </div>
        </div>
      </div>
        <h3 style="margin-top:20px;" v-if="!posts">No posts available at this time, please check back soon.</h3>
    </div>
</div>
    </div>
    <Footer/>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'

// Components
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'

// API Services
import BlogServices from '../services/BlogServices.js'

// Client Messaging
import { useToast } from 'primevue/usetoast'

export default {
  name: 'Blog',
  components: {
    Header,
    Footer,
  },
  setup() {
    const toast = useToast()
    const router = useRouter()
    const environment = process.env.VUE_APP_ENVIRONMENT
    const blogImagePath = process.env.VUE_APP_BLOG_IMAGES
    const posts = ref()
    const archives = ref()

    onMounted(() => {
      BlogServices.getBlogs()
      .then((response) => {
        posts.value = response.data
      })
      .then(() => {
        toast.add({severity:'error', summary: 'Error', detail: 'Something went wrong', life: 5000})
      })
      .finally(() => {
        
      }),
      BlogServices.getArchives()
      .then((response) => {
        if(response.status == 200) {
          var unfilteredArchives = response.data
          unfilteredArchives.forEach( archive => archive.month = archive.month.substring(0,7))
          const seen = new Set()
          const filtered = unfilteredArchives.filter(archive => {
            const duplicate = seen.has(archive.month)
            seen.add(archive.month)
            return !duplicate
          })
          archives.value = filtered
        }
      })
    })

    const showDetail = (post) => {
      router.push({ name:"BlogDetail", query: {blogID: post.id} })
    }

    const getArchivedMonth = (ids) => {
      const data = ids.split(",").map(Number)
      BlogServices.getBlogsById(data)
      .then((response) => {
        if(response.status != 200) {
          toast.add({severity:'error', summary: 'Error', detail: 'Something went wrong', life: 5000})
        } else {
          posts.value = response.data
        }
      })
    }

    return {
      environment,
      posts,
      blogImagePath,
      archives,
      showDetail,
      getArchivedMonth
    }
  },
  methods: {
    shortPost(post) {
      return post.replace(/<\/?[^>]+>/ig, " ").substring(0,330) + "..."
    },
    postDate(value) {
      let date = new Date(value)
      date = date.toLocaleString("en-CA", {
      month: 'long',
      day: '2-digit',
      year: 'numeric',
      timeZone: 'UTC'
      })
      if(date == "Invalid Date") {
          date = "--"
      }
      return date
    },
    postArchiveDate(value) {
      let date = new Date(value)
      date = date.toLocaleString("en-CA", {
      month: 'long',
      year: 'numeric',
      timeZone: 'UTC'
      })
      if(date == "Invalid Date") {
          date = "--"
      }
      return date
    },
  },
}
</script>

<style lang="scss">
  .small-image {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
  }
</style>
